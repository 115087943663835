@font-face {
    font-family: "Conthrax SB";
    src:
      url(./fonts/conthrax-sb.woff2) format("woff2"),
      url(./fonts/conthrax-sb.woff) format("woff"),
      url(./fonts/conthrax-sb.ttf) format("truetype");
    font-display: swap;
}
  
@font-face {
  font-family: "Nokia Cellphone FC";
  src:
    url(./fonts/nokia-cellphone-fc.woff2) format("woff2"),
    url(./fonts/nokia-cellphone-fc.woff) format("woff"),
    url(./fonts/nokia-cellphone-fc.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Rubber Nipple Factory";
  src:
    url(./fonts/rubber-nipple-factory.woff2) format("woff2"),
    url(./fonts/rubber-nipple-factory.woff) format("woff"),
    url(./fonts/rubber-nipple-factory.ttf) format("truetype");
  font-display: swap;
}

:root {
  --nokia-bgcolor: #3f3d46;
  --nokia-radius-shape: 16% 16% 14% 14% / 31% 31% 54% 54%; /* Monkyja */
  --nokia-logo-font: "Conthrax SB";
  --nokia-phone-font: "Nokia Cellphone FC";
  --nokia-number-font: "Rubber Nipple Factory";
}

body {
  transition: background 1s;
}

body.dark {
  background: #222;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
}

.down {
  display: flex;
  justify-content: center;
}

@keyframes rotate {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes on {
  0%,
  100% { transform: translateY(0); }
  50% { transform: translateY(-50px); }
}

@keyframes glow {
  0% { filter: drop-shadow(0 0 5px cyan); }
  100% { filter: drop-shadow(0 0 15px cyan); }
}

.nokia3310 {
  width: 170px;
  height: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--nokia-bgcolor);
  background-image:
    linear-gradient(to right, #0005 15%, transparent),
    linear-gradient(
      111deg,
      #2f2b31,
      #7d8299
    );
  box-shadow:
    0 0 8px 4px #0006,
    0 0 8px 4px #0005 inset;
  border-radius: var(--nokia-radius-shape);
  position: relative;
}

.nokia3310.rotate {
    animation: rotate 5s ease-in-out infinite;
  }

.nokia3310 .nokia-head-container {
    width: 90%;
    height: 61%;
    border-radius: 25% 25% 60% 60% / 40% 40% 50% 50%;
    transform: translateY(5px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    box-shadow:
      0 6px 0 #ddd inset,
      -8px -16px 0 #ddd inset,
      -12px -16px 0 #aaa inset,
      8px -16px 0 #ddd inset,
      12px -16px 0 #eee inset;
  }

.nokia3310 .nokia-head-container .speaker {
      box-shadow:
        6px 0 8px #aaa3 inset,
        -2px 0 6px #000a inset;
      margin-top: 8px;
      margin-bottom: 3px;
      border-radius: 50%;
      width: 9px;
      height: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      padding: 2px 0;
    }

.nokia3310 .nokia-head-container .speaker .hole {
        background: #000;
        width: 4px;
        height: 2px;
        border-radius: 50%;
        box-shadow: 0 0 2px #0009;
      }

.nokia3310 .nokia-head-container .logo {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 55px;
      height: 15px;
      font-family: var(--nokia-logo-font);
      font-size: 12px;
      color: #dfdfda;
      background: #111;
      margin-bottom: 8px;
      border-left: 1px solid #444;
      border-right: 1px solid #444;
    }

.nokia3310 .nokia-head-container .screen-container {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #78a47f;
      width: 106px;
      height: 78px;
      border-radius: 8px / 10px 10px 35px 35px;
      border: 1px solid #000a;
      border-top: 2px solid #000a;
      box-shadow:
        0 0 8px #000 inset,
        -4px 0 2px #fff8,
        4px 0 2px #2228;
      font-family: var(--nokia-phone-font);
      font-size: 12px;
      color: #2e4434;
      transition: filter 0.5s, background 1s;
    }

.nokia3310 .nokia-head-container .screen-container span {
        opacity: 0;
        transition: opacity 1s;
      }

.nokia3310 .nokia-head-container .screen-container input {
        opacity: 1;
        transition: opacity 1s;
        width: 80px;
        height: 50px;
        background: #78a47f;
        color: #000000;
        border: 0;
        font-family: var(--nokia-phone-font);
        border: 0;
        outline:0;
      }

.nokia3310 .nokia-head-container .screen-container input:focus {
        outline: none !important;
      }

.nokia3310 .nokia-head-container .bottom-oval {
      margin-top: auto;
      width: 100%;
      height: 50px;
      background: #ddd;
      border-radius: 60%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

.nokia3310 .nokia-head-container .bottom-oval .big.button.top {
          background: #ddd;
          background-image: linear-gradient(110deg, #ddd, #aaa);
          border: 2px solid #000a;
          width: 75px;
          height: 20px;
          border-radius: 50% 50% 50% 50% / 30% 30% 70% 70%;
          transform: translateY(-4px);
          display: flex;
          justify-content: center;
        }

.nokia3310 .nokia-head-container .bottom-oval .big.button.top::before {
            content: "";
            display: block;
            width: 30px;
            height: 4px;
            border: 4px solid #2992a1;
            border-radius: 50%;
            transform: translateY(1px);
            clip-path: polygon(0 55%, 100% 55%, 100% 100%, 0 100%);
          }

.nokia3310 .bottom-buttons {
    width: 80%;
    display: flex;
    justify-content: space-between;
    position: absolute;
  }

.nokia3310 .bottom-buttons .big.button {
      position: relative;
      transform: translateY(200px);
    }

.nokia3310 .bottom-buttons .big.button.left {
        background: #ccc;
        background-image: linear-gradient(to bottom, #ddd 55%, #aaa);
        border: 2px solid #000a;
        width: 40px;
        height: 20px;
        border-radius: 31px 11px 11px 21px / 22px 20px 20px;
        transform: translate(5px, 189px) rotate(36deg);
        overflow: hidden;
      }

.nokia3310 .bottom-buttons .big.button.left span {
          font-family: var(--nokia-number-font);
          font-size: 12px;
          display: block;
          transform: rotate(-35deg) translate(10px, 3px);
        }

.nokia3310 .bottom-buttons .big.button.left::after {
          content: "";
          background-color: #eee;
          height: 100%;
          width: 100%;
          border-radius: 50%;
          position: absolute;
          transform: translateY(-100%);
          z-index: -1;
        }

.nokia3310 .bottom-buttons .big.button.right {
        background: #ddd;
        background-image: linear-gradient(to bottom, #ddd 55%, #aaa);
        border: 2px solid #000a;
        width: 55px;
        height: 25px;
        border-radius: 7px 48px 36px 30px / 5px 16px 11px 15px;
        transform: translateY(189px) rotate(-34deg) skew(-12deg);
        display: flex;
        justify-content: space-around;
        overflow: hidden;
      }

.nokia3310 .bottom-buttons .big.button.right::after {
          content: "";
          background-color: #eee;
          height: 100%;
          width: 100%;
          border-radius: 50%;
          position: absolute;
          transform: translateY(-50%);
          z-index: -1;
        }

.nokia3310 .bottom-buttons .big.button.right span {
          font-family: var(--nokia-phone-font);
          font-size: 12px;
          display: block;
          transform: translate(0, 4px);
        }

.nokia3310 .keyboard {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0 6px;
    width: 85%;
    height: 110px;
    margin-top: 8px;
  }

.nokia3310 .keyboard .button-key-container {
      font-family: var(--nokia-number-font);
      font-weight: bold;
      font-size: 14px;
    }

.nokia3310 .keyboard .button-key-container.invert .button-key .special {
        order: 2;
      }

/* Left */

.nokia3310 .keyboard .button-key-container:nth-child(1) { transform: translate(-1px, -5px); }

.nokia3310 .keyboard .button-key-container:nth-child(4) { transform: translate(2px, -2px); }

.nokia3310 .keyboard .button-key-container:nth-child(7) { transform: translate(5px, 1px); }

.nokia3310 .keyboard .button-key-container:nth-child(10) { transform: translate(8px, 4px); }

/* Middle */

.nokia3310 .keyboard .button-key-container:nth-child(2) { transform: translate(1px, 0); }

.nokia3310 .keyboard .button-key-container:nth-child(5) { transform: translate(1px, 3px); }

.nokia3310 .keyboard .button-key-container:nth-child(8) { transform: translate(1px, 6px); }

.nokia3310 .keyboard .button-key-container:nth-child(11) { transform: translate(1px, 9px); }

/* Right */

.nokia3310 .keyboard .button-key-container:nth-child(3) { transform: translate(2px, -5px); }

.nokia3310 .keyboard .button-key-container:nth-child(6) { transform: translate(-1px, -2px); }

.nokia3310 .keyboard .button-key-container:nth-child(9) { transform: translate(-4px, 1px); }

.nokia3310 .keyboard .button-key-container:nth-child(12) { transform: translate(-7px, 4px); }

.nokia3310 .keyboard .button-key-container .button-key {
        display: flex;
        justify-content: center;
        position: relative;
        z-index: 0;
        user-select: none;
      }

.nokia3310 .keyboard .button-key-container .button-key:active {
          cursor: pointer;
          transform: translateY(1px);
        }

.nokia3310 .keyboard .button-key-container .button-key:active.middle {
            transform: translateY(6px);
          }

.nokia3310 .keyboard .button-key-container .button-key:active::before {
            box-shadow: 0 2px 0 #0004;
          }

.nokia3310 .keyboard .button-key-container .button-key.left .minitext { transform: translate(0, 3px); }

.nokia3310 .keyboard .button-key-container .button-key.left::before {
          --rotation: 15deg;

          border-radius: 33% 67% 43% 36% / 58% 72% 28% 42%;
        }

.nokia3310 .keyboard .button-key-container .button-key.middle { transform: translateY(5px); }

.nokia3310 .keyboard .button-key-container .button-key.middle::before { border-radius: 48% 52% 50% 50% / 40% 40% 60% 60%; }

.nokia3310 .keyboard .button-key-container .button-key.right::before {
          --rotation: -15deg;

          border-radius: 67% 33% 36% 43% / 72% 58% 42% 28%;
        }

.nokia3310 .keyboard .button-key-container .button-key::before {
          --y: -5px;

          content: "";
          display: block;
          width: 37px;
          height: 20px;
          position: absolute;
          background: #dedee0;
          background-image: linear-gradient(#dedee0, #aaa);
          border-radius: 50%;
          border: 2px solid #000c;
          box-shadow: 0 0 3px #0004;
          z-index: -1;
          transform: translateY(var(--y, 0)) rotate(var(--rotation, 0));
        }

.nokia3310 .keyboard .button-key-container .button-key span {
          font-family: var(--nokia-phone-font);
          font-size: 8px;
          font-weight: lighter;
          color: #111;
        }

.nokia3310 .keyboard .button-key-container .button-key span.special {
            font-weight: bolder;
            font-size: 10px;
            color: #000;
          }

.nokia3310 .keyboard .button-key-container .button-key span.minitext.rotate {
            display: block;
            transform: translateX(4px) rotate(-90deg);
          }

.nokia3310 .keyboard .button-key-container .button-key span.minitext {
            transform: translateY(4px);
          }

.nokia3310 .keyboard .home {
      border: 1px solid #000;
      border-top: 0;
      width: 4px;
      height: 4px;
      margin-right: 2px;
      margin-top: 2px;
      display: block;
    }

.nokia3310 .keyboard .home::before,
      .nokia3310 .keyboard .home::after {
        content: "";
        display: block;
        width: 1px;
        height: 5px;
        border-left: 1px solid #000;
        position: absolute;
        transform: translate(0, -3px) rotate(45deg);
      }

.nokia3310 .keyboard .home::after {
        transform: translate(3px, -4px) rotate(-45deg);
      }

.nokia3310 .keyboard .compact {
      letter-spacing: -4px;
    }

button {
  font-family: var(--nokia-phone-font);
  text-shadow: 0 1px 0 #000;
  background: #375dda;
  color: #fff;
  border: 0;
  padding: 5px 15px;
  border-radius: 10px;
  box-shadow: 0 4px 0 #2044b9;
}

button:disabled {
    color: #888;
    background: #555;
    box-shadow: 0 4px 0 #444;
  }

button:active {
    transform: translateY(2px);
    background: #1c3895;
    box-shadow: 0 2px 0 #2044b9;
  }

.submit {
  padding-bottom: 15px;
}

.input-nick {
  margin: 5px;
}

.sms {
  display: inline-block;
}

.mms {
  display: inline-block;
}

.progress {
  display: inline-block;
}